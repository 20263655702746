/* Centers the entire post page */
.post-page {
  font-family: Arial, sans-serif;
  max-width: 1250px; /* Increased width */
  margin: 0 auto;
  padding: 10px; /* Reduced padding */
  text-align: center; /* Centers text content */
}

.blog-category-links{
  font-size:40px;
  font-weight:bold;
  text-align:center;
  margin-bottom:20px;
  margin-top: 20px;
  /* color : #6941C6; */
  text-transform: capitalize;
}

/* Post Title */
.post-title {
  font-size: 45px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
  color : #6941C6;
}
.rich-text p{
  color : #000000;
  font-size : 17px;
}
/* Responsive Title - Adjusts for small screens */
@media (max-width: 768px) {
  .post-title {
      font-size: 2em;
  }
}

@media (max-width: 480px) {
  .post-title {
      font-size: 1.8em;
  }
}

/* Post Description */
.post-description {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 15px; /* Reduced bottom margin */
}
.single-blogcard-date {
  font-size: 1rem;
  font-weight: 500;
  line-height: 40px;
  color: #818181;
  font-family: 'Asap', sans-serif;
  margin-top: 10px;
}

/* Responsive Description */
@media (max-width: 768px) {
  .post-description {
      font-size: 1.1em;
  }
}

@media (max-width: 480px) {
  .post-description {
      font-size: 1em;
  }
}

/* Image Styling - Centered & Responsive */
.post-image {
  margin: 0 auto;
  display: block;
  width: 100%;
  /* max-width: 900px; Increased max width */
  height: auto; /* Maintains aspect ratio */
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.5) 10px 7px 29px 0px;
}

/* Smaller Image on Small Screens */
@media (max-width: 768px) {
  .post-image {
      max-width: 95%; /* Adjusted for better fit */
  }
}

/* Post Content */
.post-content {
  padding: 20px;
  text-align: left;
  margin-right: auto;
}

/* Blockquote / Quote Styling */
.quote {
  font-style: italic;
  background: #f8f9fa;
  padding: 20px;
  border-left: 5px solid #007bff;
  border-radius: 5px;
  margin: 35px auto;
  max-width: 950px;
  text-align: left;
}

/* Smaller Quote Text for Small Screens */
@media (max-width: 768px) {
  .quote {
      font-size: 0.9em;
      padding: 15px;
  }
}

/* Author & Meta Information */
.meta-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #777;
  font-size: 0.9em;
  margin-bottom: 10px; /* Reduced margin */
}

/* Responsive Meta Info */
@media (max-width: 768px) {
  .meta-info {
      flex-direction: column;
      gap: 5px;
  }
}

/* Button Styling (Share Button) */
.share-button {
  display: inline-block;
  padding: 8px 16px;
  border: 2px solid #007bff;
  color: #007bff;
  background: transparent;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.share-button:hover {
  background: #007bff;
  color: white;
}
