/* Main container */
.privacy-policy-container {
    padding: 20px;
    line-height: 1.6;
  }
  
  /* Header section */
  .main-section-header {
    /* background-color: #f5f5f5; */
    padding: 20px 0;
    text-align: center;
  }
  
  .main-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
  }
  
  .subheader {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Content section */
  .privacy-policy-content {
    background-color: #fff;
    padding: 20px;
  }
  
  p,li{
    font-size : 15px ;
  }
  h2 {
    color: #333;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  h3 {
    /* color: #444; */
    font-size: 1.4rem;
    margin-top: 20px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .main-container {
      padding: 10px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    h3 {
      font-size: 1.2rem;
    }
  }
  