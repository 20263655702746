.subheader{
    font-size: 14px;
    font-weight: 500 !important;
    color: #6941C6 !important;
    background: #f1eafc;
    border-radius: 14px;
    padding: 7px 14px;
}

body{
    background-image: url(../images/Blog_Background.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center; 
    min-height: 100vh; 
}

.container {
    max-width: 1250px !important;
    margin-top:20px;
}

.h1 {
    margin-top:7px;
    font-size: 48px !important;
    color: #42307D !important;
    font-weight: 600 !important;
}

.paragraph{
    margin-top: 10px ;
    color: #6941C6 !important;
    font-size: 19px !important;
    font-weight: 400 !important;
}

.blog-hero-image{
    box-shadow: none;
    padding: 15px;
    height : 250px;
}

.blog-card{
    border-radius: 0px;
    height: 450px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px ;
}
.card:hover{
   border: 1px solid #6941C6;
}

.card-body{
    padding: 1rem;
    padding-top: 0rem;
}
.main-container{
  padding : 15px 15px 15px 15px !important;
}
.card-title-head{
    font-size: 14px;
    font-weight: 600;
    color: #6941C6;
}
.main-section-header {
    background-color: #f5f5f5 !important;
    /* margin-top: 20px; */
    /* margin-bottom: 50px; */
    padding: 0px 0px 0px 0px;
    padding-bottom: 0px;
}
.card-text-blog{
    font-size: 15px;
    font-weight: 400;
    color: #667085;

}

.card-title{
    font-size: 20px;
    font-weight: 600;
    margin: 0; 
}

.card-text{
    font-size: 16px;
    font-weight: 400;
    color: #667085;
}

.card-text-writer{
    font-size: 14px;
    margin-bottom: 0px;
    color : #101828;
}

.card-date{
    color: #667085;
    font-size: 14px;
    font-weight: 400;
}
.read-more {
    text-decoration: none;
    color:#101828;
    font-size:24px;
}
.read-more:hover {
    color: #6941C6;
}


@media screen and (max-width: 479px) {
    
    .card-h1{
        text-align: center !important;
        font-size: 36px !important;
    }


  }