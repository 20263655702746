/* ContactSection.css */

/* General styles */
.contact-section {
    padding: 50px 0;
    background-color: #f4f4f4;
  }
  
  .contact-header {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .contact-text {
    margin-bottom: 20px;
  }
  
  .contact-info {
    text-align: center;
  }
  
  .contact-info .btn-secondary {
    background-color: #ff6f61;
    border: none;
    color: #fff;
  }
  
  .contact-info .btn-secondary:hover {
    background-color: #e55b4f;
  }
  
  .contact-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form .form-row {
    margin-bottom: 15px;
  }
  
  .contact-form .form-group {
    margin-bottom: 15px;
  }
  
  .contact-form .form-control {
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .contact-form .form-control:focus {
    border-color: #ff6f61;
    box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.25);
  }
  
  .contact-form .btn-primary {
    background-color: #ff6f61;
    border: none;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
  }
  
  .contact-form .btn-primary:hover {
    background-color: #e55b4f;
  }
  
  .form-response {
    margin-top: 20px;
  }
  
  .form-success,
  .form-fail {
    display: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.875rem;
  }
  
  .form-success {
    color: #28a745;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
  }
  
  .form-fail {
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .contact-info {
      text-align: center;
    }
  }

  .response-message {
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    color: white;
  }
  
  .response-message.success {
    background-color: green;
  }
  
  .response-message.error {
    background-color: red;
  }