.function-text {
    font-size: 1rem;
  }

.main-container{
  overflow: hidden;
}


  @media (max-width: 767px) {
    .container {
      padding: 1rem;
    }
    .function-text {
      font-size: 0.5rem;
    }
  }
  
 