/* Brand.css */

/* General styles for the brand section */
.main-section-header {
    background-color: #ffffff; /* Optional: Light background color */
  }
  
  /* Custom image styles */
  .brand-img {
    max-height: 100px; /* Adjust maximum height for images */
    object-fit: contain; /* Ensure images fit within the container */
  }
  
  /* Responsive adjustments */
  
  /* Mobile view (up to 576px) */
  @media (max-width: 576px) {
    .brand-img {
      max-height: 80px; /* Adjust image height */
    }
  }
  
  /* Tablet view (577px to 992px) */
  @media (min-width: 577px) and (max-width: 992px) {
    .brand-img {
      max-height: 90px; /* Adjust image height */
    }
  }

  
  
  /* Desktop view (993px and up) */
  @media (min-width: 993px) {
    .brand-img {
      max-height: 100px; /* Adjust image height */
    }
  }
  