/* Header.css */

/* Sticky header for fixed position */
.wrap_sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  /* Navbar styling */
  .navbar-outer {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo-brand-1 {
    max-height: 40px; /* Adjust as needed for logo size */
    width: 80%;
  }
  
  /* Navbar items spacing */
  .navbar-nav .nav-item {
    margin-left: 15px;
  }  

  
  /* Responsive design adjustments */
  
  /* Mobile view (up to 576px) */
  @media (max-width: 576px) {
    .navbar-nav {
      text-align: center;
    }
  
    .navbar-nav .nav-item {
      margin-left: 0;
      margin-bottom: 10px;
    }
  
   
  }
  
  /* Tablet view (577px to 992px) */
  @media (min-width: 577px) and (max-width: 992px) {
    .navbar-nav {
      text-align: center;
    }
  
    .navbar-nav .nav-item {
      margin-left: 0;
      margin-bottom: 10px;
    }
  
  
  }
 
  
  /* Desktop view (993px and up) */
  @media (min-width: 993px) {
    .navbar-nav .nav-item {
      margin-left: 15px;
    }
  
    .btn-primary {
      margin-top: 0;
    }
  }
  /* Mobile view media query */
@media only screen and (max-width: 768px) {
  .logo-brand-1 {
    max-height: 60px;
    width: 100%;
  }
}
  