/* MainSection.css */

.main-section-header {
    padding: 2rem 0;
    background-color: #ffffff; /* Optional: Background color */
  }
  
  .main-container {
    /*max-width: 100%;*/
    margin: 0 auto;
    padding: 0 1rem; /* Adjust as needed */
  }
  
  .content-wrap_center {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .trade-in-header-testt {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .spacer_xs {
    height: 1rem;
  }
  
  .spacer_xxl {
    height: 3rem;
  }
  
  .text-block-3 {
    font-size: 1.125rem;
    margin-top: 1rem;
  }
  
  .video iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* Maintain aspect ratio */
  }
  
  .container-trade-in {
    padding: 1rem 0;
  }
  
  /* .div-desk-upper, .div-desk-lower, .div-desk-lower-mobile, .div-upper-mobile {
    margin-top: 2rem;
  } */
  
  .background-video-container {
    position: relative;
    height: 440px; 
  }
  
  @media (max-width: 768px) {
    .background-video-container {
      height: 270px; 
    }
    .main-section-video-text {
      text-align: center!important;
    }
    .mb-mobile-video-text {
      margin-bottom: 2.5rem;
    }
  }
  
  
  @media (max-width: 575.98px) {
    .div-desk-upper, .div-desk-lower {
      display: none;
    }
    .div-upper-mobile, .div-desk-lower-mobile {
      display: flex;
      flex-direction: column;
    }
  }
  