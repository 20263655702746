/* HeroSection.css */

/* General styling for the section */
.section-intro {
    /* background-color: #f8f9fa; Optional: Light background color */
    padding: 2rem 0; /* Optional: Vertical padding */
  }
  
  .text-gradient {
    background: linear-gradient(90deg, rgba(255,0,150,1) 0%, rgba(0,204,255,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Responsive adjustments */
  
  /* Mobile view (up to 576px) */
  @media (max-width: 576px) {
    .display-4 {
      font-size: 2.5rem; /* Adjust font size */
    }
  
    .lead {
      font-size: 1rem; /* Adjust font size */
    }
  
    .btn-primary-hero {
      font-size: 0.9rem; /* Adjust button font size */
      padding: 0.75rem 1.25rem; /* Adjust button padding */
    }
  
    .img-fluid {
      max-height: 80px; /* Adjust image height */
    }
  }
  
  /* Tablet view (577px to 992px) */
  @media (min-width: 577px) and (max-width: 992px) {
    .display-4 {
      font-size: 3rem; /* Adjust font size */
    }
  
    .lead {
      font-size: 1.25rem; /* Adjust font size */
    }
  
    .btn-primary-hero {
      font-size: 1rem; /* Adjust button font size */
      padding: 0.875rem 1.5rem; /* Adjust button padding */
    }
  
    .img-fluid {
      max-height: 90px; /* Adjust image height */
    }
  }
  
  /* Desktop view (993px and up) */
  @media (min-width: 993px) {
    .display-4 {
      font-size: 4rem; /* Adjust font size */
    }
  
    .lead {
      font-size: 1.5rem; /* Adjust font size */
    }
  
    .btn-primary-hero {
      font-size: 1.1rem; /* Adjust button font size */
      padding: 1rem 1.75rem; /* Adjust button padding */
    }
  
    .img-fluid {
      max-height: 100px; /* Adjust image height */
    }
  }
  